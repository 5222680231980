import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState, useEffect, useRef } from "react";
import { Messages } from "primereact/messages";
import { Paginator } from "primereact/paginator";
import { useHistory } from "react-router";
import { Panel } from "primereact/panel";
import { BreadCrumb } from "primereact/breadcrumb";
import * as constants from "../app_utils/constants/Constants";

import * as labels from "../app_utils/constants/Labels";
import useShowModalDialog from "../app_utils/components/ShowModalHook";
import { HOME_ROUTE_PATH } from "../app_utils/route_paths/resolver/PageRoutes";
import { PrimeIcons } from "primereact/api";
import { BaseApiServiceImpl } from "../app_utils/api/BaseApiServiceImpl";
import { MessageUtils } from "../app_utils/utils/MessageUtils";
import {
  formatAmountWithCommas,
  generalStatusBodyTemplate,
  replaceWithUnderscore,
  sanitizeValue,
  toReadableDate,
  userDetailsLinkTemplate,
} from "../app_utils/utils/Utils";
import { getFilterComponent } from "../app_utils/components/Filters";
import { paginatorTemplate } from "../app_utils/components/PaginatorTemplate";
import { filtersHeadertemplate } from "../app_utils/components/FiltersPanelHeader";
import UserFormDialogView from "./UserFormDialogView";
import ProductCategoryFormDialog from "./ProductCategoryFormDialog";
import { Image } from "primereact/image";

const OrdersView = () => {
  const [records, setRecords] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchTermFilter, setSearchTermFilter] = useState<string | null>(null);
  const [recordStatusFilter, setRecordStatusFilter] = useState<string | null>(null);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [first, setFirst] = useState<number>(0);
  const [limit, setLimit] = useState<number>(constants.MAXIMUM_RECORDS_PER_PAGE);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [productcategoryTypeFilter, setproductcategoryTypeFilter] = useState<any>(null);
  const [productcategoryTypes, setproductcategoryTypes] = useState<any>([]);
  const [expandedRows, setExpandedRows] = useState<any>(null);
  let offset = 0;

  const message = useRef<any>();
  const history = useHistory();

  const { openDialog, toggleOpenDialog } = useShowModalDialog();

  /**
   * These are the bread crumbs that serve as the title of the page
   */
  const breadcrumbHome = {
    icon: "pi pi-home",
    command: () => {
      history.push(HOME_ROUTE_PATH);
    },
  };

  const breadcrumbItems = [
    {
      label: `Orders`,
      icon: PrimeIcons.USERS,
    },
  ];

  /**
   * This gets the parameters to submit in the GET request to back office
   * @returns
   */
  const getQueryParameters = () => {
    let searchParameters: any = { offset: offset, limit: limit };
    if (searchTermFilter !== null) searchParameters.searchTerm = searchTermFilter;
    if (recordStatusFilter !== null) searchParameters.recordStatus = recordStatusFilter;
    if (productcategoryTypeFilter !== null)
      searchParameters.productcategoryTypeId = productcategoryTypeFilter;

    return searchParameters;
  };

  /**
   * This fetches counties from the back office using the search parameters
   */
  const fetchRecordsFromServer = () => {
    setIsLoading(true);
    let searchParameters: any = getQueryParameters();

    new BaseApiServiceImpl("/api/v1/orders")
      .getRequestWithJsonResponse(searchParameters)
      .then(async (response) => {
        setIsLoading(false);
        setRecords(response?.records);
        setTotalItems(response?.totalItems);
      })
      .catch((error) => {
        setIsLoading(false);
        MessageUtils.showErrorMessage(message, error.message);
      });
  };
  /**
   * This fetches counties from the back office using the search parameters
   */
  const fetchProductCategoryTypes = () => {
    new BaseApiServiceImpl("/api/v1/product-categories")
      .getRequestWithJsonResponse({ offset: 0, limit: 0 })
      .then(async (response) => {
        setproductcategoryTypes(response?.records);
      })
      .catch((error) => {
        MessageUtils.showErrorMessage(message, error.message);
      });
  };

  /**
   * This hook is called everytime the page is loaded
   */
  useEffect(() => {
    fetchRecordsFromServer();
    fetchProductCategoryTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * This is used everytime the user presses the enter button on any form input of a filter
   */
  const onSubmitFilter = () => {
    setSearchTermFilter(searchTermFilter);
    setRecordStatusFilter(recordStatusFilter);
    setproductcategoryTypeFilter(productcategoryTypeFilter);
    fetchRecordsFromServer();
  };

  /**
   * This is used to clear all filters and resubmit the get request to the back office
   */
  const resetFilters = () => {
    setSearchTermFilter("");
    fetchRecordsFromServer();
  };

  /**
   * This opens the edit territory dialog form by toggling the open dialog variable
   */
  const openEditFormDialog = (selectedRecord: any) => {
    setSelectedUser(selectedRecord);
    toggleOpenDialog();
  };

  /**
   * This opens the new territory dialog form by toggling the open dialog variable
   * and setting the selected territory to null
   */
  const openNewFormDialog = () => {
    setSelectedUser(null);
    toggleOpenDialog();
  };

  /**
   * The row index template
   * @param rowData
   * @param props
   * @returns
   */
  const rowIndexTemplate = (rowData: any, props: any) => {
    return (
      <React.Fragment>
        <span>{first + (props.rowIndex + 1)}</span>
      </React.Fragment>
    );
  };

  /**
   * This is called when a paginator link is clicked
   * @param e
   */
  const onPageChange = (e: any) => {
    offset = e.page * constants.MAXIMUM_RECORDS_PER_PAGE;
    setFirst(e.first);
    setLimit(constants.MAXIMUM_RECORDS_PER_PAGE);

    fetchRecordsFromServer();
  };

  /**
   * The action body template
   * @param rowData
   * @returns
   */
  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions">
        <Button
          icon={PrimeIcons.CHECK_CIRCLE}
          className="p-button-sm p-button-success mx-2"
          onClick={() => {}}
        />
        <Button
          icon={PrimeIcons.TIMES_CIRCLE}
          className="p-button-sm p-button-danger p-ml-2"
          onClick={() => {}}
        />
      </div>
    );
  };

  /**
   * The record status row template
   * @param rowData
   * @returns
   */
  const statusBodyTemplate = (rowData: any) => {
    return generalStatusBodyTemplate(rowData?.status);
  };

  /**
   * The date  row template
   * @param rowData
   * @returns
   */
  const dateTemplate = (rowData: any) => {
    return <>{toReadableDate(rowData.chairpersonDateElected)}</>;
  };

  const userTemplate = (rowData: any) => {
    return userDetailsLinkTemplate(rowData?.user?.id, rowData?.user?.fullName);
  };
  const bigIconBody = (rowData: any) => {
    return <Image height="50" width="50" src={rowData?.bigIconUrl} />;
  };

  /**
   * The template for the filter buttons
   */
  const filterButtonsTemplate = (
    <>
      <div className="col-6  md:col-2 p-fluid" key="filterBtns">
        <Button
          icon={constants.ICON_SEARCH}
          className={constants.CSS_FILTER_SUBMIT_BUTTON}
          onClick={onSubmitFilter}
          loading={isLoading}
        />
        <Button
          icon={constants.ICON_REFRESH}
          className={constants.CSS_FILTER_RESET_BUTTON}
          onClick={resetFilters}
          loading={isLoading}
        />
      </div>
    </>
  );

  /**
   * This is a list of filters to display in the filter section
   */
  const filterDetails = [
    {
      type: "text",
      value: searchTermFilter,
      onChangeFn: setSearchTermFilter,
      id: "searchTermFilter",
      label: labels.LABEL_SEARCH_TERM,
      colWidth: constants.CSS_FILTER_SEARCH_INPUT_DIV,
      onkeydownFn: onSubmitFilter,
    },

    {
      type: "dropdown",
      value: productcategoryTypeFilter,
      onChangeFn: setproductcategoryTypeFilter,
      id: "productcategoryTypeFilter",
      label: "Category",
      options: productcategoryTypes,
      optionLabel: "name",
      optionValue: "id",
      colWidth: constants.CSS_FILTER_DEFAULT_DIV,
      onkeydownFn: onSubmitFilter,
    },
    {
      type: "dropdown",
      value: productcategoryTypeFilter,
      onChangeFn: setproductcategoryTypeFilter,
      id: "productcategoryTypeFilter",
      label: "Status",
      options: [],
      optionLabel: "name",
      optionValue: "id",
      colWidth: constants.CSS_FILTER_DEFAULT_DIV,
      onkeydownFn: onSubmitFilter,
    },
  ];

  /**
   * This loops through the filter Details array and returns the components
   * to render in the detailed form filter dialog.
   */
  const dynamicFilterDetails = filterDetails.map((filter: any) => {
    let component = getFilterComponent(filter);
    return (
      <div className={filter.colWidth} key={replaceWithUnderscore(filter.id)}>
        <label htmlFor={filter.id}>{filter.label}</label>
        {component}
      </div>
    );
  });
  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="grid">
        <div className="col-12 md:col-4">
          <div className="field mb-0">
            <label>{"Delivery Details"}</label>
            <label className="font-medium w-full">{sanitizeValue(data?.deliveryDetails)}</label>
          </div>
        </div>
        <div className="col-12 md:col-4">
          <div className="field mb-0">
            <label>{"Total Cost"}</label>
            <label className="font-medium w-full">
              {formatAmountWithCommas(data?.totalCost, null)}
            </label>
          </div>
        </div>
        <div className="col-12 md:col-4">
          <div className="field mb-0">
            <label>{"Items"}</label>
            <label className="font-medium w-full">
              {data?.items.map((item: any) => {
                return (
                  <p>
                    <label>Name : {sanitizeValue(item?.productName)}</label>
                    <label>, Quantity : {sanitizeValue(item?.quantity)}</label>
                    <label>, Unit Price : {formatAmountWithCommas(item.unitPrice, null)}</label>
                    <label>, Product Id : {sanitizeValue(item?.productId)}</label>
                  </p>
                );
              })}
            </label>
          </div>
        </div>
        <div className="col-12 md:col-4">
          <div className="field mb-0">
            <label>{"WalletTransaction"}</label>
            <label className="font-medium w-full">
              {sanitizeValue(data?.walletTransaction?.serialNumber)}
            </label>
          </div>
        </div>

        <div className="col-12 md:col-4">
          <div className="field mb-0">
            <label>{"Cancellation Reason"}</label>
            <label className="font-medium w-full">{sanitizeValue(data?.cancellationReason)}</label>
          </div>
        </div>
        <div className="col-12 md:col-4">
          <div className="field mb-0">
            <label>{"Payment Details"}</label>
            <label className="font-medium w-full">{sanitizeValue(data?.paymentDetails)}</label>
          </div>
        </div>
        <div className="col-12 md:col-4">
          <div className="field mb-0">
            <label>{"Date Paid"}</label>
            <label className="font-medium w-full">{toReadableDate(data?.datePaid)}</label>
          </div>
        </div>
        <div className="col-12 md:col-4">
          <div className="field mb-0">
            <label>{"Date Dispatched"}</label>
            <label className="font-medium w-full">{toReadableDate(data?.dateDispatched)}</label>
          </div>
        </div>

        <div className="col-12 md:col-4">
          <div className="field mb-0">
            <label>{"Date Delivered"}</label>
            <label className="font-medium w-full">{toReadableDate(data?.dateDelivered)}</label>
          </div>
        </div>
        <div className="col-12 md:col-4">
          <div className="field mb-0">
            <label>{"Date Cancelled"}</label>
            <label className="font-medium w-full">{toReadableDate(data?.dateCancelled)}</label>
          </div>
        </div>
        <div className="col-12 md:col-4">
          {data.status === "PaidPendingDispatch" && (
            <Button
              icon={PrimeIcons.CHECK_CIRCLE}
              label="Finished Packing"
              className="p-button-sm p-button-success mx-2"
              onClick={() => {}}
            />
          )}
          {data.status === "PaidPendingDispatch" && (
            <Button
              icon={PrimeIcons.CHECK_CIRCLE}
              label="Delivery dispatched"
              className="p-button-sm p-button-success mx-2"
              onClick={() => {}}
            />
          )}
          {data.status === "PaidPendingDispatch" && (
            <Button
              icon={PrimeIcons.TIMES_CIRCLE}
              label="Delivered"
              className="p-button-sm p-button-danger p-ml-2"
              onClick={() => {}}
            />
          )}
        </div>
      </div>
    );
  };
  return (
    <div className="grid">
      <div className="col-6 flex justify-content-start flex-wrap">
        <BreadCrumb home={breadcrumbHome} model={breadcrumbItems} />
      </div>
      <div className="col-6 flex justify-content-end flex-wrap">
        <Button
          label={"Add Value"}
          icon={PrimeIcons.PLUS}
          className="p-button-secondary"
          onClick={openNewFormDialog}
        />
      </div>
      <Messages ref={message} style={{ width: "100%" }} />
      <div className="col-12">
        <Panel headerTemplate={filtersHeadertemplate} toggleable>
          <div className="grid">
            {dynamicFilterDetails}
            {filterButtonsTemplate}
          </div>
        </Panel>
      </div>
      <div className="col-12">
        <div className="card">
          <DataTable
            value={records}
            paginator={false}
            className="datatable-responsive"
            paginatorPosition="both"
            emptyMessage="No record found."
            loading={isLoading}
            expandedRows={expandedRows}
            onRowToggle={(e: any) => setExpandedRows(e.data)}
            rowExpansionTemplate={rowExpansionTemplate}
          >
            <Column expander style={{ width: "3em" }} />
            <Column
              field="Index"
              header="#"
              style={{ width: "70px" }}
              body={rowIndexTemplate}
            ></Column>
            <Column field="serialNumber" header={"Order ID"}></Column>
            <Column body={userTemplate} header={"User"}></Column>

            <Column field="name" header={"Products"}></Column>
            <Column field="totalCost" header={"Total Cost"}></Column>
            <Column field="checkoutType" header={"Checkout Type"}></Column>
            <Column header={labels.LABEL_STATUS} body={statusBodyTemplate}></Column>
          </DataTable>

          <Paginator
            first={first}
            rows={constants.MAXIMUM_RECORDS_PER_PAGE}
            totalRecords={totalItems}
            alwaysShow={true}
            onPageChange={onPageChange}
            template={paginatorTemplate}
          />
        </div>
      </div>
      <ProductCategoryFormDialog
        isOpen={openDialog}
        toggle={toggleOpenDialog}
        messageRef={message}
        record={selectedUser}
        reloadFn={fetchRecordsFromServer}
      ></ProductCategoryFormDialog>
    </div>
  );
};

export default OrdersView;
