/**
 * User session key placeholders
 */
export const APP_NAME = "MY GUARDIAN ERP";
export const KEY_APP_VERSION = "v1.0.0"; // This represents the current running app version that has to be updated when making a release
/**
 * This has been moved from the .env file because it was not being set as an environment variable. React does not
 * read directly from the environment and therefore the read has to be done from the .env file.
 * Also, the risk of other people using this key is minimized by setting the URIs in the Google Console.
 * More research needs to be done to read the variable from the .env file
 */
export const REACT_APP_GOOGLE_CLIENT_ID =
  "631187462392-bu7ph8e7nis0olor367ugdt5iooghmei.apps.googleusercontent.com";
export const KEY_BEARER_TOKEN = "KEY_BEARER_TOKEN";
export const KEY_REFRESH_TOKEN = "KEY_REFRESH_TOKEN";
export const KEY_USER_DETAILS = "KEY_USER_DETAILS";
export const KEY_SELECTED_APPLICATION_MODULE = "KEY_SELECTED_APPLICATION_MODULE";
export const KEY_SELECTED_OBJECT = "KEY_SELECTED_OBJECT";
export const KEY_IS_LOGGED_IN = "KEY_IS_LOGGED_IN";
export const KEY_RANDOM_DEVICE_ID = "KEY_RANDOM_DEVICE_ID";
export const MAXIMUM_RECORDS_PER_PAGE: number = 20;
export const SEARCH_DATE_FILTER_FORMAT = "yy-mm-dd";
export const SEARCH_DATE_TIME_FILTER_FORMAT = "yy-mm-dd";
export const TITLE_FILTER_HEADER = "Filters";
export const NULLISH_DATE = "0001-01-01T00:00:00+00:00";

/**
 * This is the main content layout id. It houses the container that has the main display.
 * Use it in scenarios where where you want to replace the current content without
 * modifying the URL
 */
export const ID_LAYOUT_CONTENT = "layoutContent";

export const ICON_SEARCH = "pi pi-search";
export const ICON_REFRESH = "pi pi-refresh";
export const CSS_FILTER_SUBMIT_BUTTON = "p-button-primary mr-2 ml-2 mt-5";
export const CSS_FILTER_RESET_BUTTON = "p-button-secondary mr-2 ml-2 mt-5";
export const CSS_FILTER_SEARCH_INPUT_DIV = "field col-12 md:col-4 p-fluid";
export const CSS_FILTER_SEARCH_INPUT_NORMAL_DIV = "field col-12  md:col-4 p-fluid";
export const CSS_FILTER_DEFAULT_DIV = "field col-12  md:col-2 p-fluid";
export const CSS_FILTER_SMALL_DIV = "field col-12  md:col-1 p-fluid";
export const CSS_FILTER_MEDIUM_DIV = "field col-3 p-fluid";
export const CSS_ADD_NEW_BUTTON = "p-button-outlined p-button-indigo mr-2 mb-2";
export const CSS_EDIT_BUTTON = "p-button-rounded p-button-success mr-2";
export const CSS_MD_COL_3 = "md:col-3";
export const CSS_MD_COL_12 = "md:col-12";
export const CSS_COL_2 = "col-2";
export const CSS_COL_3 = "col-3";
export const CSS_COL_4 = "col-4";
export const CSS_COL_6 = "col-6";
export const CSS_COL_8 = "col-8";
export const CSS_COL_9 = "col-9";
export const CSS_COL_10 = "col-10";
export const CSS_COL_12 = "col-12";

export const CSS_BACK_BUTTON = "p-button-secondary mr-2 mb-2";
export const LABEL_BACK_BUTTON = "Back To List";
export const LABEL_BACK = "Back";
export const ICON_BACKWARD = "pi pi-fast-backward";
export const ICON_ADD_NEW = "pi pi-plus";
export const ICON_EDIT = "pi pi-pencil";
export const ICON_SYNC = "pi pi-sync";
export const MINIMUM_FILTER_QUERY_LENGTH = 3;
export const BACKEND_KNOWN_HTTP_ERROR_CODES = [400, 403, 415, 500];

export const RECORD_STATUSES = [
  { id: "ACTIVE", name: "Active" },
  { id: "DELETED", name: "Deleted" },
  { id: "ACTIVE_LOCKED", name: "Active Locked" },
];
export const PRODUCT_LISTING_TYPE = [
  { id: "Product", name: "Product" },
  { id: "Service", name: "Service" },
];
export const NOTIFICATION_ORIGINS = [{ id: "SYSTEM", name: "System" }];
export const NOTIFICATION_DESTINATION = [{ id: "HOME", name: "Home" }];
export const FUNDRAISING_PLAN_TYPES = [
  {
    id: 1,
    name: "Open",
  },
  {
    id: 2,
    name: "Fixed Value",
  },
  {
    id: 4,
    name: "Recurring and Fixed",
  },
];

export const ADVERT_TYPES = [
  {
    id: "GENERAL",
    name: "General",
  },
  {
    id: "SHOP",
    name: "Shop",
  },
  {
    id: "WALLET",
    name: "Wallet",
  },
];
export const LOOKUP_TYPES = [
  {
    id: "LANGUAGES",
    name: "Languages",
  },
  {
    id: "SO_CANCELLATION_REASON",
    name: "SOS Cancellation Reason",
  },
  {
    id: "APP_REFERRER",
    name: "App Referrers",
  },
];
export const CURRENCIES = [
  {
    id: 2,
    name: "UGX",
    currency: "Uganda Shillings",
    country_code: "ug",
    currency_code: "UGX",
    exchange_rate: 3815.11,
  },
  {
    id: 3,
    name: "KSH",
    currency: "Kenya Shillings",
    country_code: "ke",
    currency_code: "KSH",
    exchange_rate: 131.36,
  },
  {
    id: 4,
    name: "TSH",
    currency: "Tanzania Shillings",
    country_code: "tz",
    currency_code: "TSH",
    exchange_rate: 2637.3,
  },
  {
    id: 5,
    name: "RIYAL",
    currency: "Saudi Riyal",
    country_code: "sar",
    currency_code: "SAR",
    exchange_rate: 3.75,
  },
];
export const AGG_TXN_TYPES = [
  {
    id: "DEPOSIT",
    name: "Deposit",
  },
  {
    id: "WITHDRAW",
    name: "Withdraw",
  },
];
export const TXN_MODES = [
  {
    id: "BANK",
    name: "Bank",
  },
  {
    id: "MOBILE_MONEY",
    name: "Mobile Money",
  },
  {
    id: "CARD",
    name: "Card",
  },
];
export const TXN_STATUSES = [
  {
    id: "PENDING",
    name: "Pending",
  },
  {
    id: "SUCCESSFUL",
    name: "Successful",
  },
  {
    id: "FAILED",
    name: "Failed",
  },
  {
    id: "CANCELED",
    name: "Cancelled",
  },
  {
    id: "INDETERMINATE",
    name: "Indeterminate",
  },
  {
    id: "PENDING_3DS_AUTHORISATION",
    name: "Pending 3DS Authorisation",
  },
  {
    id: "PENDING_AVS_AUTHORISATION",
    name: "Pending AVS Authorisation",
  },
  {
    id: "PENDING_PIN_AUTHORISATION",
    name: "Pending Pin Authorisation",
  },
  {
    id: "PENDING_OTP_VALIDATION",
    name: "Pending OTP Validation",
  },
  {
    id: "FAILED_AUTHORISATION",
    name: "Failed Authorization",
  },
];

export const DEFAULT_IMAGE =
  "https://www.pngitem.com/pimgs/m/30-307416_profile-icon-png-image-free-download-searchpng-employee.png";
// Supported Countries
export const SupportedTerritories = [
  { territory: "Uganda", countryIsoCode: "UG" },
  { territory: "Kenya", countryIsoCode: "KE" },
];
// Regular Expressions
export const EmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

// Known links
export const APP_BASE_CONTEXT =
  window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/#";
